<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      :title="title"
      slot="title"
      link="/helpContent/announcement"
    />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-4">
      <InputTextArea v-model="form.title" :maxlength="100" rules="required" label="Announcement title" />
      </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-2">
      <InputDate v-model="form.expireDate" rules="required" label="Expire Date"  />
      </div>
      </FormRow>
      <FormRow>
      <div class="col-sm-12">
      <label class="InputTextArea_label_35O-v">Announcement<span class="InputTextArea_required_1qWYF">*</span></label>
      <EditorComponent v-model="form.body" rules="required" label="Announcement" />
      </div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (infoMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import InputDate from 'Components/form/InputDate';
import { getAnnouncementList, addUpdateAnnouncement } from "../api";

import { ValidationObserver } from "vee-validate";

export default {
  name: "add",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    InputDate,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      form:{
      expireDate:'',
      title:null,
      body:null,
      id:0
      },
      title:"Add Announcement",
    };
  },
  created() {
    if (this.$route.params.id) {
      this.showLoader();
      this.form.id = atob(this.$route.params.id);
      this.getData();
      this.title="Edit Announcement";
    }
  },
  methods: {
    getData() {
      setTimeout(() => {
                        this.showLoader();
                        const filters = {};
                        filters.id = atob(this.$route.params.id);
                        const data = {
                            filterjson: {
                                filter: [filters]
                            }
                        };
                        getAnnouncementList(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    )
      this.hideLoader();
    },
    sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            id:this.form.id,
                            expireDate: this.form.expireDate,
                            title: this.form.title,
                            body: this.form.body
                        };
                        addUpdateAnnouncement(data).then((res)=>{
                            this.form = {};
                            this.form.body = '';
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            handleResponse(response) {
                this.hideLoader();
                this.form.expireDate = response.data[0].ExpiresOn;
                this.form.title = response.data[0].Title;
                this.form.body = response.data[0].Body;
            },
  },
};
</script>
